import React from 'react';
import PostCard from './PostCard';
import { Link } from 'react-router-dom';

function LatestPosts({ posts, onPostClick }) {
  const l = posts.length;
  const latestPosts = posts.slice(l - 3, l);

  return (
    <div className="latest-posts">
      <div className="cards">
        {latestPosts.reverse().map(post => (
          <PostCard key={post.id} post={post} onClick={() => onPostClick(post)} />
        ))}
      </div>
      <Link to="/all-posts">
        <button className="see-all-posts-button">See all</button>
      </Link>
    </div>
  );
}

export default LatestPosts;
