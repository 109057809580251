import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LatestPosts from './components/LatestPosts';
import PostContent from './components/PostContent';
import AllPosts from './components/AllPosts';
import { loadMarkdown } from './loadMarkdown';

const postsData = [
  { id: 1, title: 'The Impact and Future of ChatGPT', fileName: '23jul.md' },
  { id: 2, title: 'Symbolic - Album Review', fileName: '24jul.md' },
  { id: 3, title: 'My Top 5 Albums of 2024 Q1', fileName: '25jul.md' },
];

function App() {
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);

  useEffect(() => {
    const loadPosts = async () => {
      const loadedPosts = await Promise.all(
        postsData.map(async (post) => {
          const content = await loadMarkdown(post.fileName);
          return { ...post, content };
        })
      );
      setPosts(loadedPosts);
      setSelectedPost(loadedPosts[0]);
    };

    loadPosts();
  }, []);

  const handlePostClick = async (post) => {
    const content = await loadMarkdown(post.fileName);
    setSelectedPost({ ...post, content });
  };

  return (
    <Router>
      <div className="App">
        <div id="heading-container">
          <h1><strong><span id="welcome">Welcome to Aryamaan's Blog!</span></strong></h1>
        </div>

        <h2>My Latest Posts</h2>
        <Routes>
          <Route path="/" element={
            <>
              <LatestPosts posts={posts} onPostClick={handlePostClick} />
              {selectedPost && <PostContent post={selectedPost} />}
            </>
          } />
          <Route path="/all-posts" element={<AllPosts posts={posts} onPostClick={handlePostClick} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
