import React from 'react';

function PostCard({ post, onClick }) {
  const excerpt = post.content.length > 50 ? post.content.substring(0, 50) + '...' : post.content;

  return (
    <div className="post-card" onClick={onClick}>
      <h3>{post.title}</h3>
      <p>{excerpt}</p>
    </div>
  );
}

export default PostCard;
