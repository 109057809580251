import React from 'react';
import PostCard from './PostCard';

function AllPosts({ posts, onPostClick }) {
  return (
    <div className="all-posts">
      {posts.map(post => (
        <PostCard key={post.id} post={post} onClick={() => onPostClick(post)} />
      ))}
    </div>
  );
}

export default AllPosts;
